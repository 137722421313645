.main_blog_container {
  width: 60%;
  margin: auto;
  margin-top: 40px;
}

.blogTitleHolder {
  margin-bottom: 5px;
}

.blog_title_text_style {
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0px;
}

.image_contaienr {
  width: 100%;
  height: 300px;
}

.image_style {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog_content_holder {
  text-align: left;
  font-size: 20px;
  margin-top: 30px;
  font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times,
    serif;
  line-height: 32px;
  letter-spacing: -0.003em;
  color: rgba(41, 41, 41, 1);
}

.blog_action_btn_holder {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  flex-wrap: wrap;
}

.blog_info_holder {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.auther_info {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.auther_info p {
  padding: 5px 10px;
  margin-bottom: 0px;
  font-size: 20px;
  color: gray;
}

.author_icon_style {
  font-size: 20px;
  color: gray;
}
/* text Ui css */

blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
pre {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
  padding: 15px;
  border-radius: 5px;
}

.add_new_blog_button {
  width: 99%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .main_blog_container {
    width: 80%;
  }

  .blog_action_btn_holder {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    flex-wrap: wrap;
  }
}
