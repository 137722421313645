.agency_list_container {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.unverified_agencies_holder {
  margin-top: 100px;
}

@media only screen and (max-width: 800px) {
  .agency_list_container {
    width: 90%;
  }
}
