.university_label_style {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.university_user_action_btn_holder {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
