.update_delete_btn_holder {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.delete_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  margin-right: 30px;
}

.update_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.accept_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  margin-left: 30px;
}

.agent_list_holder {
  margin-top: 50px;
}
