.UniversityCard_container {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.167);
  border-radius: 20px;
  padding: 20px;
}
.university_card_basic_detail_box {
  display: flex;
}
.university_cover_img {
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.university_card_basic_detail_sub_box {
  margin-left: 20px;
}
.university_card_uni_name {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #192f59;
  margin-bottom: 5px;
  text-align: start;
}
.university_card_uni_city {
  text-align: start;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #646464;
  margin-bottom: 5px;
}
.university_card_btn_container {
  text-align: start;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.university_card_heart_icon_red {
  color: red;
  font-size: x-large;
}
.university_card_heart_icon_gray {
  color: gray;
  font-size: x-large;
}
.university_card_sub_image {
  object-fit: cover;
  width: 220px;
  padding: 2px;
  border-radius: 10px !important;
  height: 80px;
}
.university_card_sub_image_large {
  object-fit: cover;
  width: 550px;
  height: 160px;
  padding: 2px;
  border-radius: 10px !important;
}
.university_card_sub_image_box {
  display: flex;
  margin: 10px auto;
}
.university_card_uni_desc {
  text-align: justify;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #505050;
}
.university_card_view_btn_container {
  display: flex;
  justify-content: flex-end;
}
/* .university_card_view_btn_container button {
  background-color: #3db166; 
  border: none;
  color: white;
  padding: 3px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  border-radius: 5px;
} */

.verified_icon_style {
  font-size: 25px;
  color: #3897f0;
}

@media only screen and (max-width: 750px) {
  .university_card_sub_image {
    width: 150px;
    height: 60px;
  }
  .university_card_sub_image_large {
    width: 500px;
    height: 120px;
  }
}

@media only screen and (max-width: 450px) {
  .university_card_btn_container button {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 420px) {
  .university_card_sub_image {
    width: 100px;
    height: 40px;
  }
  .university_card_sub_image_large {
    width: 400px;
    height: 80px;
  }
}
