.course_detail_thumbnail_container {
  position: relative;
  z-index: 0;
}
.course_detail_thumbnail_img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  margin-top: -50px;
  filter: brightness(40%);
}
.course_main_details_card {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 500px;
  background: white;
  border-radius: 20px;
  padding: 15px;
}
.course_main_details_type_container {
  display: flex;
  justify-content: space-between;
}
.course_main_details_type_sub_container {
  display: flex;
  justify-content: flex-start;
}
.course_main_details_type_sub_container p {
  background-color: #efefef;
  color: #192f59;
  padding: 8px 15px;
  margin-right: 10px;
  border-radius: 10px;
}
.crs_name_container {
  margin-left: 5px;
}
.course_main_details_card_crs_name {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #192f59;
  margin-bottom: 5px;
  text-align: start;
}
.course_main_details_card_btn_container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.course_desc {
  width: 70%;
  margin: auto;
  font-size: 20px;
  font-weight: 400;
}

.course_main_details_card_btn_container button {
  margin-top: 15px;
}
.course_highlight_container {
  display: flex;
  justify-content: space-evenly;
  background-color: #192f59;
  width: 80%;
  color: white !important;
  margin: auto;
  border-radius: 20px;
  padding: 20px;
  margin-top: -70px;
  z-index: 1;
  position: relative;
}
.course_highlight_container_icons {
  font-size: 40px;
}
.course_highlight_container p {
  margin: 0px;
}
.course_details_sub_container {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.course_other_details {
  width: 65%;
  margin: auto;
  margin-top: 50px;
}

.detail_holder {
  width: 100%;
  margin: auto;
  background-color: #f2f2f2;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 3.95px 1.95px 5.6px !important;
  border-radius: 15px;
}

.other_detail_box_style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  margin-bottom: 10px;
  /* background-color: #3db166; */
}

.other_field_heading {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
}

.other_field_value {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.other_icon_style {
  font-size: 30px;
  margin-bottom: 10px;
}

.public_university_detail_holder {
  width: 80%;
  margin: auto;
  margin-top: 50px;
}

.university_cover {
  margin-top: 50px;
}

.public_university_cover_img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.university_other_detail_container {
  width: 80%;
  margin: auto;
}

.university_name_style {
  margin-top: 10px;
  font-size: 40px;
  font-weight: 600;
}

.university_description {
  width: 100%;
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  color: gray;
}

.other_image_holder {
  margin-bottom: 20px;
}

.other_image_style {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.university_other_img_container {
  width: 80%;
  margin: auto;
  margin-top: 50px;
}

.university_location_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.country_name_style {
  color: #f2f2f2;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-left: 10px;
  text-align: left;
}

.city_name_style {
  color: #f2f2f2;
  font-size: 18px;
  margin-bottom: 0px;
  margin-left: 10px;
  text-align: left;
}

.country_detail_holder {
  display: flex;
  justify-content: flex-start;
}

.country_info_box {
  margin-right: 20px;
}

.link_to_courseDetails {
  color: #f2f2f2;
  font-size: 18px;
  margin-bottom: 0px;
  margin-left: 10px;
  text-align: right;
  cursor: pointer;
  font-weight: 700;
}

.link_to_countryDetails {
  color: #000000;
  font-size: 18px;
  margin-bottom: 0px;
  margin-left: 10px;
  text-align: right;
  cursor: pointer;
  font-weight: 700;
}

.desc_para {
  color: #f2f2f2;
  font-size: 18px;
  margin-bottom: 0px;
  margin-left: 10px;
  text-align: left;
  white-space: normal;
  background: "none";
  margin-bottom: 8px;
}

@media only screen and (max-width: 700px) {
  .course_highlight_container {
    width: 95%;
  }
  .course_highlight_container_icons {
    font-size: 25px;
  }
  .course_highlight_container h5 {
    font-size: medium;
  }
  .course_highlight_container p {
    font-size: small;
  }
  .course_details_sub_container {
    width: 95%;
  }
  .university_other_detail_container {
    width: 95%;
  }
  .university_description {
    width: 95%;
  }
}

@media only screen and (max-width: 550px) {
  .course_details_sub_container {
    width: 100%;
  }

  .university_other_detail_container {
    width: 100%;
  }

  .course_highlight_container {
    width: 100%;
    margin-top: 0px;
    border-radius: 0px;
    padding: 20px 5px;
  }
  .course_main_details_card {
    width: 300px;
  }

  .course_desc {
    width: 90%;
  }

  .university_description {
    width: 90%;
  }
}
