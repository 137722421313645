.admin_action_btn_holder {
  width: 70%;
  margin-right: 30px;
  display: flex;
  justify-content: flex-start;
}
.admin_action_btn_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.action_admin_btn_style {
  width: 180px;
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 11px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

@media only screen and (max-width: 350px) {
  .admin_action_btn_holder {
    display: block;
  }
  .action_admin_btn_style {
    margin-top: 10px;
  }
  .admin_action_btn_container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
