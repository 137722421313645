.title_text_style {
  font-size: 25px;
  font-weight: 600;
  color: black;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.horizonral_green_line {
  width: 10%;
  margin: auto;
  margin-top: 10px;
  border-top: 5px solid green;
}
