.main_footer_container {
  width: 100%;
  height: 100%;
  background-color: #192f59;
}

.footer_content_holder {
  width: 92%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.footer__main_col_style {
  max-width: 30%;
  min-width: 270px;
  text-align: left;
}

.footer_logo_style {
  width: 250px;
  height: 60px;
}

.footer_desc_text_style {
  color: white;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.footer_col_style {
  max-width: 20%;
  min-width: 200px;
  margin-top: 60px;
}

.link_content_container {
  margin-top: 35px;
}

.link_list_title_style {
  font-size: 18px;
  color: white;
  font-weight: 600;
}

.footer_link_text_style {
  color: rgba(255, 255, 255, 0.705);
  text-decoration: none;
  font-size: 17px;
  font-weight: 400;
  display: block;
  margin-bottom: 15px;
}

.contact_content_holder {
  width: 92%;
  margin: auto;
  margin-top: 30px;
  border-top: 3px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_terms_text_style {
  color: white;
  font-size: 15px;
}

.icons_holder {
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_icon_style {
  font-size: 20px;
  color: white;
  cursor: pointer;
}

.footer_icon_style:hover {
  color: gray;
}

@media only screen and (max-width: 700px) {
  .contact_content_holder {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer__main_col_style {
    padding-top: 20px;
  }
}
