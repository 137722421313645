.create_new_user_btn_holder {
  width: 78%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.create_new_use_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 11px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.change_password_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 11px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  margin-right: 10px;
}

.reject_change_password_btn_style {
  border: none;
  background-color: white;
  color: rgb(255, 0, 0);
  padding: 9px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  margin-right: 10px;
  border: 2px solid rgb(255, 58, 58);
}

.admin_list_table_holder {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.add_user_icon_style {
  font-size: 25px;
  color: white;
  margin-right: 10px;
}

@media only screen and (max-width: 650px) {
  .admin_list_table_holder {
    width: 95%;
  }
}
