.btn_to_home {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  margin-right: 12px;
}

.Heading404 {
  font-size: 7.5em;
  margin: 15px 0px;
  font-weight: bold;
}
.sub_heading_404 {
  font-weight: bold;
}
