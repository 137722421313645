.about_us_curious_heading_input {
  width: 98%;
  border: none;
  font-size: 50px;
  text-align: left;
  font-weight: 700;
  color: #008000;
  line-height: 1.2;
  height: auto;
}

.about_us_curious_heading_input:focus {
  outline: none;
}

.curious_sub_text_input {
  width: 98%;
  border: none;
  font-size: 20px;
  text-align: left;
  color: gray;
}

.curious_sub_text_input:focus {
  outline: none;
}

.image_input_hiddden_style {
  display: none;
}

.update_Button_holder {
  display: flex;
}

/* Our Story Area */

.strory_text_field {
  border: none;
  height: 100%;
  width: 98%;
  text-align: left;
  font-size: 16px;
  color: gray;
}

.strory_text_field:focus {
  outline: none;
}

.team_member_name {
  border: none;
  font-size: 22px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.team_member_name:focus {
  outline: none;
}
.team_member_position_style {
  border: none;
  font-size: 18px;
  color: gray;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.team_member_position_style:focus {
  outline: none;
}
.add_team_member_container {
  margin: auto;
  margin-top: 80px;
}
.team_member_delete_btn {
  color: red;
  font-size: 20px;
}
.team_member_add_icon {
  font-size: 60px;
}
.update_team_member_btn {
  background-color: #3db166;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 5px 20px;
  margin: 0px 15px;
}
.update_goals_btn {
  background-color: #3db166;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 3px 20px;
  font-size: 14px;
  margin: 0px 15px;
}
.aboutus_form_input_style {
  width: 95%;
  border: none;
  background: transparent;
}
.aboutus_form_input_style:focus {
  outline: none;
}

.icon_image_style {
  width: 35px;
  height: 35px;
  margin-top: 5px;
}

/* end */
