.application_status_chnage_text {
  text-align: left;
  margin-bottom: 0px;
}

.sub_text_style {
  color: gray;
  font-size: 14px;
  text-align: left;
}

.status_change_button_holder {
  display: flex;
  justify-content: flex-start;
}

.status_change_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.close_application_btn_holder {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 400px) {
  .status_change_btn_style {
    width: 95%;
    margin: auto;
    margin-top: 20px;
  }
}
