.new_course_detail_container {
  width: 60%;
  margin: auto;
}

.update_course_btn_holder {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.update_course_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.delete_course_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  margin-right: 20px;
}

.course_input_container {
  width: 100%;
  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.course_view_label_style {
  display: block;
  text-align: left;
  margin-bottom: 20px;
}

.course_label_style_view {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}
.course_info_field {
  display: block;
  width: 90%;
  padding: 8px 10px 8px 10px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  font-size: 17px;
}
.course_desc_container {
  margin-top: 100px;
}

.course_description_holder {
  margin-top: 40px;
}

.course_desc_text_style {
  font-size: 16px;
  color: gray;
}

/*  */
.who_we_are_conatiner {
  width: 100%;
  margin: auto;
  margin-top: 150px;
}

.who_we_are_content_holder {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.who_we_are_img_style {
  position: absolute;
  height: 215px;
  width: 330px;
  object-fit: cover;
  border-radius: 10px;
  top: 10%;
  left: 15%;
}

.img_border {
  position: relative;
  border: 15px solid #192f59;
  border-radius: 20px;
  height: 300px;
  width: 35%;
}
.desc_title_holder {
  width: 55%;
}

.who_we_are_desc {
  font-size: 16px;
  margin-top: 30px;
  color: gray;
}

.short_border_who_we_are {
  width: 130px;
  border-bottom: 3px solid #3db166 !important;
  margin-left: 40px;
}

.document_required_section {
  margin-top: 100px;
}

.Document_heading {
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0px;
}

.document_info_text_style {
  text-align: left;
}

.single_document_check_holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.document_heading_txt_style {
  margin-bottom: 5px;
  margin-left: 15px;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  color: black;
  text-align: left;
}

.document_heading_txt_style:hover {
  color: black;
}

.document_icon_style_check {
  font-size: 25px;
  color: green;
}

.document_icon_style_uncheck {
  font-size: 25px;
  color: red;
}

.work_english_requirement {
  margin-top: 100px;
}

.data_holder {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: auto;
  margin-top: 20px;
}

.vertical_line {
  height: 150px;
  border: 1px solid rgb(201, 201, 201);
}

.requirement_heading {
  font-size: 20px;
  font-weight: 600;
}

.english_test_score_container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.score_text_style {
  margin-right: 10px;
}

.requirement_text_style {
  font-weight: 600;
}

@media only screen and (max-width: 800px) {
  .new_course_detail_container {
    width: 90%;
  }
}
