.agency_form {
  width: 60%;
  margin: auto;
}

.agency_input_container {
  width: 100%;
  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.agency_field_holder {
  display: block;
  text-align: left;
  margin-bottom: 20px;
}

.agency_label_style {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
}

.agency_input_field_style {
  display: block;
  width: 100%;
  padding: 8px 10px 8px 10px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  font-size: 17px;
}

.multiple_field_holder {
  width: 90%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.multipleInputStyle {
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  padding: 2px;
}

.agency_input_field_style_multiple_input {
  display: block;
  width: 90%;
  padding: 8px 10px 8px 10px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 15px;
}

.minus_icon_style {
  margin-top: 10px;
  cursor: pointer;
}

.add_service_icon_style {
  font-size: 30px;
  margin-left: 10px;
  padding-bottom: 5px;
  cursor: pointer;
}

.update_agency_profile_btn_holder {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.update_agency_profile_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 10px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

@media only screen and (max-width: 800px) {
  .agency_form {
    width: 90%;
  }
}
