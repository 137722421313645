.agency_agent_list_holder {
  width: 80%;
  margin: auto;
}

.add_agent_btn_holder {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.accepted_status_text {
  font-size: 20px;
  font-weight: 600;
  color: #3db166;
  background-color: #3db1665b;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
}

.decesion_making_btn_holder {
  display: flex;
  justify-content: flex-end;
}

.rejected_status_text {
  font-size: 20px;
  font-weight: 600;
  color: rgb(255, 68, 68);
  background-color: rgba(255, 0, 0, 0.151);
  padding: 10px;
  width: 100%;
  border-radius: 10px;
}

.comment_text_style {
  font-size: 17px;
  color: rgb(255, 116, 116);
}

.add_agent_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  margin-right: 12px;
}

@media only screen and (max-width: 800px) {
  .agency_agent_list_holder {
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .decesion_making_btn_holder {
    display: block;
  }
}
