.agency_application_submit_btn_holder {
  margin: auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}

.agency_detail_container_add_application {
  width: 100%;
  margin: 40px auto !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;
  align-items: center;
  border-radius: 10px;
}
