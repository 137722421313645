.video_container {
  position: relative;
}
.play_video_bkg {
  filter: brightness(40%);
}
.play-pause-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  /* background: rgba(61, 177, 102, 0.716); */
  background: #192f59e1;

  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}
.pause_video_title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 60%);
  color: white;
}
.play-pause-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -50%);
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 30px solid white;
}

.play-pause-button.playing {
  display: none;
}

.play-pause-button.paused::before {
  display: block;
}

.video_style {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}
