.blog_abstract_box {
  width: 90%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  background-color: white;
  position: relative;
}

.blog_abdtract_image_style {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.blog_abdtract_content_holder {
  padding: 15px;
}

.abstract_blog_title_style {
  color: #192f59;
  font-size: 17px;
  font-weight: 500;
  font-weight: 700;
  text-align: left;
}

.description_holder {
  width: 100%;
  height: 100px;
  overflow-y: hidden;
  font-size: 14px !important;
  text-align: left !important;
  position: relative;
  color: rgb(148, 148, 148) !important;
  font-weight: 600;
  margin-bottom: 45px;
}

.abstract_gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 17%,
    rgba(88, 87, 110, 0) 80%
  );
}

.abstract_box_blog_button_holder {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 20px;
  margin-top: 10px;
}
