.change_passwrod_form_holder {
  width: 50%;
  margin: auto;
  margin-top: 30px;
}

.password_label_field_holder {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.password_label_style {
  width: 20%;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  color: black;
}

.password_input_field_style {
  width: 70%;
  margin: auto;
  padding: 10px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  font-size: 17px;
}

.password_save_btn_holder {
  width: 75%;
  display: flex;
  justify-content: flex-start;
}

.save_password_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 11px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

@media only screen and (max-width: 800px) {
  .change_passwrod_form_holder {
    width: 90%;
  }
}
