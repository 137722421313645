.update__forms {
  text-align: start;
}

.font-weight-bold {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0px 10px 0px;
}
.blog_img_style {
  width: 200px;
  height: 200px;
}

.uploaded_blog_img_label {
  width: 100%;
  height: 300px;
  margin-top: 10px;
  border: 3px dashed rgb(218, 218, 218);
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploaded_blog_img_style {
  width: 100%;
  height: 287px;
  object-fit: cover;
}

.margin_left_style {
  margin-left: 10px;
}
