.add_course_container {
  width: 60%;
  margin: auto;
}

.input_container {
  width: 100%;
  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.course_image_and_detail_style {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course_thumbnail_input_container {
  width: 30%;
}

.document_input_field_actual_style {
  display: none;
}

.course_input_label_style {
  height: 150px;
  width: 150px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.course_upload_placeholder_img_style {
  height: 120px;
  width: 120px;
}

.course_label_field_holder_flex_style {
  display: block;
  text-align: left;
  margin-bottom: 20px;
}

.course_input_label_style_with_image {
  height: 150px;
  width: 150px;
  padding: 3px;
  border: 3px dashed rgb(192, 192, 192);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.course_thumnail_style {
  height: 142px;
  width: 145px;
  object-fit: cover;
}

.course_label_style {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.course_input_field_style_with_img {
  display: block;
  width: 90%;
  padding: 8px 10px 8px 10px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  font-size: 17px;
}

.course_input_field_style_with_img_textArea {
  display: block;
  width: 97%;
  padding: 8px 10px 8px 10px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  font-size: 17px;
}

.work_exp_container {
  width: 97%;
  margin: auto;
}

.english_prof_container {
  width: 97%;
  margin: auto;
}

.label_and_add_btn_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.course_label_style_multiple {
  width: 70%;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}

.conformationCheckBox {
  margin-left: 20px;
  margin-top: 5px;
  padding: 5px;
}

.add_work_exp_icon_style {
  font-size: 25px;
}

.work_option_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.work_field_holder {
  width: 45%;
}

.minus_icon_style {
  font-size: 25px;
}

.document_checker {
  padding: 10px;
  /* border: 1px solid black; */
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.document_name_text_style {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-left: 10px;
}

.document_icon_style {
  font-size: 30px;
  color: rgb(150, 150, 150);
  cursor: pointer;
}

.document_checker_checked {
  font-size: 30px;
  color: green;
  cursor: pointer;
}

.add_new_doc_btn_holder {
  width: 96%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add_doc_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.submit_btn_holder {
  display: flex;
  justify-content: flex-end;
}

.submit_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.other_doc_field_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
}

.other_doc_close_icon_style {
  font-size: 25px;
}

.doc_field_solo_holder {
  width: 75%;
}

@media only screen and (max-width: 800px) {
  .add_course_container {
    width: 90%;
  }
  .course_image_and_detail_style {
    display: block;
  }
  .course_thumbnail_input_container {
    width: 90%;
    margin: 0px auto 20px auto;
  }
  .basic_detail_holder {
    width: 100%;
  }
}
