.university_list_table_holder {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.action_button_holder {
  width: 98%;
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  margin-top: 30px;
  flex-wrap: wrap;
}

.date_range_keeper {
  position: relative;
  width: fit-content;
}

.date_picker_holder {
  position: absolute;
  top: 100%;
  left: 0%;
}

.date_show_input {
  width: 100% !important;
  height: 45px !important;
}

.applciation_input_field_style {
  border: 1px gray solid;
  padding: 5px 5px;
  width: 20%;
  border-radius: 10px;
  color: gray;
  min-width: 150px;
}

::placeholder {
  color: gray !important;
}

.applciation_input_field_style:focus {
  outline: none;
}

.add_university_navigation_btn {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.clear_icon_style {
  font-size: 25px;
  color: white;
}

.unverified_universities_holder {
  margin-top: 100px;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .add_university_navigation_btn {
    margin-top: 10px;
  }
  .applciation_input_field_style {
    margin-top: 10px;
  }
  .university_list_table_holder {
    width: 90%;
  }
}
