.course_card_main_container {
  background: #efefef;
  box-shadow: 0px 0px 5px rgba(103, 103, 103, 0.167);
  border-radius: 20px;
  padding: 20px;
  text-align: start;
  cursor: pointer;
}
.course_card_basic_details_box {
  display: flex;
}
.course_card_cover_img {
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 8px;
}
.course_card_crs_name {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #192f59;
  margin-bottom: 5px;
  text-align: start;
}
.course_card_basic_detail_sub_box {
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.course_card_crs_details_box {
  margin: 15px auto;
}
.course_card_dates {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #192f59;
  margin-bottom: 5px;
  text-align: start;
  margin-left: 5px;
}
.course_card_date_container {
  display: flex;
  justify-content: space-between;
}
.course_card_crs_desc {
  text-align: start;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #646464;
}
.course_card_free_container {
  display: flex;
  justify-content: space-evenly;
}
.course_card_free_sub_container {
  background-color: #d9d9d9;
  padding: 5px 0px;
  width: 45%;
  text-align: center;
  border-radius: 15px;
}
.course_card_free_sub_container p {
  margin-bottom: 2px;
}
.course_card_apply_btn_container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.course_card_apply_btn {
  background-color: #192f59;
  border: none;
  color: white;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
}
