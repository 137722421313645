.download_pdf_button_holder {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.download_pdf_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.rejection_textarea_style {
  width: 100%;
  margin-top: 10px;
  height: 150px;
  padding: 10px;
}

.pending_status_text {
  font-size: 20px;
  font-weight: 600;
  color: gray;
  background-color: rgba(65, 65, 65, 0.116);
  padding: 10px;
  width: 100%;
  border-radius: 10px;
}

.requirement_status_text {
  font-size: 20px;
  font-weight: 600;
  color: rgb(243, 86, 65);
  background-color: rgba(255, 136, 39, 0.384);
  padding: 10px;
  width: 100%;
  border-radius: 10px;
}
