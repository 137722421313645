.custom_button {
  background-color: #192f59; /* Green */
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  margin-right: 12px;
}

.small_custom_button {
  background-color: #192f59;
  border: none;
  color: white;
  padding: 3px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 12px;
}

.secondary_custom_button {
  background-color: #6c757d; /* Green */
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  margin-right: 12px;
}

.long_button {
  width: 100%;
}

.custom_button:hover .small_custom_button:hover {
  background-color: #132a53;
}
