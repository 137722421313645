.why_join_us_container {
  width: 80%;
  margin: auto;
  margin-top: 100px;
}

.block_title {
  text-align: center;
}

.title_text_style {
  font-size: 32px;

  font-weight: 500;
  margin-bottom: 0;
}

.short_border {
  width: 130px;
  margin: auto;
  border-bottom: 3px solid #3db166 !important;
}

.why_join_us_desc_holder {
  text-align: center;
}

.why_join_desc {
  text-align: center;

  font-weight: 400;
  margin-top: 30px;
}

/* WO WE ARE */
.who_we_are_conatiner {
  width: 80%;
  margin: auto;
  margin-top: 150px;
}

.who_we_are_content_holder {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.who_we_are_img_style {
  position: absolute;
  height: 300px;
  width: 120%;
  object-fit: cover;
  border-radius: 10px;
  top: 10%;
  left: 15%;
}

.img_border {
  position: relative;
  border: 15px solid #192f59;
  border-radius: 20px;
  height: 400px;
  width: 30%;
}
.desc_title_holder {
  width: 55%;
}

.who_we_are_desc {
  font-size: 16px;
  margin-top: 30px;
}

.short_border_who_we_are {
  width: 130px;
  border-bottom: 3px solid #3db166 !important;
  margin: auto;
}

/* HOW DOES IT WORK */

.how_does_it_work_container {
  width: 80%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 50px;
}

.stepper_container {
  width: 80%;
  margin: auto;
  margin-top: 70px;
}

.green_circle {
  margin: auto;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #192f59;
}

.steps_style {
  margin: auto;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.steps_style_reverse {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.step_container {
  width: 40%;
  text-align: right;
  font-size: 28px;
  font-weight: 500;
  color: #192f59;
}
.step_container_reverse {
  width: 40%;
  text-align: left;
  font-size: 28px;
  font-weight: 500;
  color: #192f59;
}

.circle_container {
  width: 10%;
}
.description_container {
  width: 40%;

  font-weight: 500;
}
.horizontal_line {
  width: 0%;
  height: 100px;
  border-right: 10px solid black;
  margin: auto;
}

.recruiter_home_page_btn_holder {
  margin-top: 50px;
  width: 100%;
  text-align: center;
}

.recruiter_join_us_btn {
  background-color: #3db166;
  border: none;
  color: white;
  padding: 12px 41px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

/* MEDIA QUERY */

@media only screen and (max-width: 375px) {
  .steps_style {
    display: block;
  }
  .steps_style_reverse {
    display: block;
  }
  .circle_container {
    display: none;
  }
  .step_container_reverse {
    width: 100% !important;
    margin: auto;
    text-align: center;
  }
  .step_container {
    width: 100% !important;
    margin: auto;
    text-align: center;
  }
  .description_container {
    width: 100% !important;
    margin: auto;
    text-align: center;
  }
}

@media only screen and (max-width: 912px) {
  .who_we_are_conatiner {
    width: 80%;
    margin: auto;
    margin-top: 50px;
  }
  .who_we_are_content_holder {
    display: block;
  }
  .desc_title_holder {
    margin-top: 30px;
    width: 100%;
    text-align: center;
  }
  .short_border_who_we_are {
    width: 130px;
    border-bottom: 3px solid #3db166 !important;
    margin: auto;
  }
  .who_we_are_img_style {
    position: absolute;
    height: 200px;
    width: 200px;
    object-fit: cover;
    border-radius: 10px;
    top: 10%;
    left: 4%;
  }
  .img_border {
    border: 15px solid #192f59;
    border-radius: 20px;
    height: 280px;
    width: 250px;
    margin: auto;
  }

  .how_does_it_work_container {
    width: 100%;
  }
  .steps_style {
    width: 100%;
    margin-top: 70px;
  }
  .steps_style_reverse {
    width: 100%;
    flex-direction: row;
    margin-top: 70px;
  }

  .description_container {
    width: 60%;
  }

  .step_container_reverse {
    width: 30%;
    text-align: center;
  }

  .step_container {
    text-align: center;
    width: 30%;
  }

  .horizontal_line {
    display: none;
  }
}

@media only screen and (max-width: 1300px) {
  .link_container_desktop {
    display: none;
  }
  .nav_btn_container {
    width: 45%;
  }
}

@media only screen and (min-width: 912px) and (max-width: 1434px) {
  .who_we_are_img_style {
    position: absolute;
    height: 250px;
    width: 120%;
    object-fit: cover;
    border-radius: 10px;
    top: 9%;
    left: 4%;
  }
  .img_border {
    border: 15px solid #192f59;
    border-radius: 20px;
    height: 330px;
    width: 36%;
  }
}
