.create_application_component_container {
  width: 60%;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 50px;
}

.title_desc_holder {
  text-align: center;
}

.title_text_style {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 0;
}

.short_border_new_application {
  width: 130px;
  margin: auto;
  border-bottom: 3px solid #3db166 !important;
}

.application_detail_component {
  width: 100%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}

.profile_photo_holder {
  width: 25%;
  text-align: center;
}

.upload_profile_photo_btn {
  display: block;
  margin: auto;
  margin-top: 13px;
  font-size: 30px;
  color: gray;
  cursor: pointer;
}

.profile_image_style {
  height: 180px !important;
  width: 180px !important;
  border-radius: 50%;
  object-fit: cover;
}

.filed_container {
  width: 75%;
  padding: 15px;
  display: flex;
  justify-content: space-evenly;
}

.label_style {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: black;
  text-align: initial;
}

.field_set_1 {
  width: 48%;
}

.field_set_2 {
  width: 48%;
}

.profile_field_style {
  width: 100%;
  margin-bottom: 30px;
  padding: 10px 5px;
  margin-top: 5px;
  border-radius: 10px;
  border: 1px solid #192f59;
  font-size: 18px;
}

.profile_field_style:focus {
  outline: none;
}

/* COURSE DETAIL CSS */

.course_detail_container {
  width: 100%;
  margin: 40px auto !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;
  /* display: flex; */

  align-items: center;
  border-radius: 10px;
}

.education_detail_container {
  width: 100%;
  margin: 40px auto !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px;
  display: flex;

  align-items: center;
  border-radius: 10px;
}

.application_submit_btn_holder {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}

.application_submit_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 15px;
}

.upload_document_actual_field {
  display: none;
}

.upload_label_style {
  padding: 22px !important;
}

.uploaded_file_name_text_style {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  width: 80%;
}

/* UPLOAD DOCUMENT SECTION */
.remove_justify_center {
  justify-content: flex-start !important;
}

.upload_doc_holder {
  text-align: center;
  cursor: pointer;
}

.upload_other_docs_container {
  border: 1px solid #b7b4b4;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  min-height: 150px;
  max-width: 210px;
  min-width: 210px;
  position: relative;
}

.upload_other_docs_container_done {
  border: 1px solid green;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  min-height: 150px;
  max-width: 210px;
  min-width: 210px;
  position: relative;
}

.file_input_icon {
  font-size: 40px;
  color: gray;
  margin-top: 10px;
}

.doc_name_text_style {
  margin-top: 11px;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 600;
  color: gray;
}

.other_upload_area {
  position: relative;
}

.check_btn_upload_style {
  position: absolute;
  right: 10px;
  font-size: 20px;
  color: green;
}

/* MEDIA QUERY */
@media only screen and (max-width: 1250px) {
  .application_detail_component {
    width: 100%;
  }

  .course_detail_container {
    width: 100%;
  }

  .education_detail_container {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .application_submit_btn_holder {
    width: 100%;
  }
}

@media only screen and (max-width: 850px) {
  .create_application_component_container {
    width: 90%;
  }
  .application_detail_component {
    display: block;
  }
  .profile_photo_holder {
    width: 100%;
    text-align: center;
  }
  .profile_image_style {
    margin: auto;
  }

  .filed_container {
    width: 100%;
  }
}

@media only screen and (max-width: 760px) {
  .filed_container {
    display: block;
  }

  .field_set_1 {
    width: 100%;
  }

  .field_set_2 {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .filed_container {
    display: block;
  }
  .field_set_1 {
    width: 100%;
  }
  .field_set_2 {
    width: 100%;
  }
}
