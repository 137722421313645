.signup_main_conatainer {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.block_title {
  text-align: center;
}

.title_text_style {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 0;
}

.short_border {
  width: 200px;
  margin: auto;
  border-bottom: 3px solid #3db166 !important;
}

.sign_up_tab_holder {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  margin-top: 100px;
}

.tab_btn_holder_cont {
  width: 33.33%;
}

.tab_btn_style {
  width: 100%;
  padding: 10px 10px 10px 10px;
  background-color: #fff;
  outline: none;
  border: 1px solid black;
  font-size: 17px;
  font-weight: 500;
}

.selected_tab_btn-style {
  width: 100%;
  padding: 10px 10px 10px 10px;
  background-color: #192f59;
  outline: none;
  border: 1px solid #192f59;
  font-size: 17px;
  font-weight: 500;
  color: #fff;
}

/* STEP 1 FORM */
.sign_in_step_1_form_holder {
  width: 60%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.step-1_form {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  padding: 15px;
}

.field_holder {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}

::placeholder {
  color: black;
  opacity: 1; /* Firefox */
}

.input_field_style {
  width: 90%;
  margin: auto;
  padding: 18px 10px 18px 18px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
}

.input_field_style:focus {
  outline: none;
}

.upload_actual_input_field {
  display: none;
}

.label_upload_style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 18px;
}

.business_cerificate_input_flex_style {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.class_upload_btn {
  background-color: #003e6b;
  padding: 6px 15px;
  border: 1px solid #003e6b;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 0px;
}

.btn_icon_style {
  font-size: 15px;
  margin-right: 10px;
}

.input_text_style {
  margin-bottom: 0;
}

.next_button {
  background-color: #4caf50;
  width: 90%;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.next_button_holder {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

/* SIGN UP STEP - 2 FORM */
.sign_in_step_2_form_holder {
  width: 60%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.step_2_holder {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  padding: 15px;
}

.selection_holder {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}

.selection_box_style {
  width: 90%;
  margin: auto;
  padding: 18px 10px 18px 18px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
}

.selection_content_style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 18px;
}

.data_container {
  width: 100%;
  margin-top: 30px;
}

.content_style {
  width: 90%;
  margin: auto;
  padding: 18px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
}

.data_container_for_input_field {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}

.content_style_input_field {
  width: 90%;
  margin: auto;
  padding: 18px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
}

.add_button_style {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option_holder_container {
  width: 90%;
  margin: auto;
}

.option_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-top: 30px;
  width: 100%;
}

.option_text_holder {
  width: 100%;
  border: 2px solid #3db166;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
}

.option_text_style {
  width: 94%;
  padding: 18px;
  border: none;
  font-size: 15px;
  color: black;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.option_text_style:focus {
  outline: none;
}

.minus_btn_holder {
  width: 5%;
}

/* SIGN UP STEP 3 */
.sign_in_step_3_form_holder {
  width: 60%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.step_3_holder {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  padding: 15px;
}

.content_style_green {
  width: 90%;
  margin: auto;
  padding: 18px;
  border: 2px solid #3db166;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

/* MEDIA QUERY */
@media only screen and (max-width: 500px) {
  .sign_up_tab_holder {
    display: block !important;
  }

  .signup_main_conatainer {
    width: 100%;
  }

  .sign_up_tab_holder {
    display: block;
  }

  .tab_btn_holder_cont {
    width: 80%;
    margin: auto;
  }
}

@media only screen and (max-width: 700px) {
  .sign_in_step_1_form_holder {
    width: 100%;
  }

  .sign_in_step_2_form_holder {
    width: 100%;
  }

  .sign_in_step_3_form_holder {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .option_holder {
    display: block;
    height: max-content;
  }

  .icon_btn_holder {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
