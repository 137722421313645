.add_university_holder {
  width: 60%;
  margin: auto;
}

.input_container {
  width: 100%;

  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.bacsic_detail_holder {
  display: flex;
  justify-content: space-between;
}

.basic_university_detail_holder {
  width: 50%;
}

.university_image_container {
  width: 48%;
}

.university_label_field_holder {
  display: block;
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
}

.university_label_style {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.university_input_field_style {
  display: block;
  width: 100%;
  padding: 8px 10px 8px 10px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  font-size: 17px;
}

.full_university_input_field_style {
  display: block;
  width: 100%;
  padding: 8px 10px 8px 10px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  font-size: 17px;
}

.university_cover_image {
  width: 100%;
  height: 200px;
  margin-top: 10px;
  border: 3px dashed rgb(218, 218, 218);
  padding: 3px;
}

.sub_images_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.sub_images_class {
  width: 32%;
  height: 150px;
  border: 3px dashed rgb(218, 218, 218);
  padding: 3px;
}

.actual_documetn_upload_input_field {
  display: none;
}

.cover_img_style {
  width: 100%;
  height: 187px;
  object-fit: cover;
}

.side_image_style {
  width: 100%;
  height: 138px;
  object-fit: cover;
}

.upload_placeholder_img_style {
  height: 120px;
  width: 120px;
  margin-top: 40px;
}

.subimage_upload_placeholder_img_style {
  height: 120px;
  width: 120px;
  margin-top: 15px;
}

.submit_button_holder {
  display: flex;
  justify-content: flex-end;
}

.add_new_univercity_btn {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

@media only screen and (max-width: 800px) {
  .add_university_holder {
    width: 90%;
  }
}
@media only screen and (max-width: 700px) {
  .bacsic_detail_holder {
    display: block;
  }
  .basic_university_detail_holder {
    width: 100%;
  }
  .university_image_container {
    width: 98%;
  }
  .university_label_style {
    width: 100% !important;
  }
}
