.home_title_video_container {
  width: 100%;
  height: 387px;
  margin: 20px auto;
}
li::marker {
  color: #3db166;
  font-size: 20px;
  height: 100%;
  line-height: 35px;
}
.home_info_staper_sub_container {
  width: 90%;
}

.home_page_staper_container {
  background-color: #192f59;
  color: white;
  width: 70%;
  padding: 20px 12% 20px 20px;
  border-radius: 20px;
}
.home_page_staper_container p {
  text-align: start;
}
.how_to_apply_step_li_container {
  display: flex;
  justify-content: flex-start;
}
.how_to_apply_step_li_container h1 {
  color: #3db166;
  font-size: 39px;
  margin-right: 15px;
  margin-top: -15px;
}
.home_page_how_to_apply_container {
  display: flex;
  width: 90%;
  margin: 20px auto;
  justify-content: flex-start;
  align-items: center;
}
.home_apply_step_video_container {
  width: 35%;
  margin-left: -100px;
  border-radius: 20px;
}
.apply_step_video_style {
  height: 320px;
  object-fit: cover;
  width: 100%;
  border-radius: 20px;
}
.blog_abstract_list_view {
  width: 100%;
  margin-top: 50px;
  background-color: #192f59;
  padding: 50px 0px;
}

.blog_items {
  width: 85%;
  margin: auto;
}

.privacy_policy_style {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.privacy_policy_style:hover {
  color: gray;
}

.subscription_part {
  width: 100%;
  background-color: #192f59;
  height: 100px;
  margin-bottom: -50px;
  /* position: relative; */
}

.subscription_container {
  /* position: absolute; */
  width: 70%;
  margin: auto;
  min-height: 180px;
  bottom: 50px;
  background-color: yellow;
  margin-bottom: -140px;
  z-index: 1;
  position: relative;
  margin-top: 70px;
  border-radius: 10px;
  background-image: linear-gradient(#0000006e, #0000006e),
    url("/public/images/HomePage/subscription_image.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}

.input_and_btn_holder {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.subscriotion_input_style {
  width: 75%;
  border-radius: 10px;
  background: #fff;
  padding: 7px;
  padding-left: 10px;
  outline: none;
  border: none;
  font-size: 20px;
}

.subscriotion_input_style:focus {
  outline: none;
}

.subscription_btn {
  width: 25%;
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 11px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.subscriotion_heading_text {
  color: #fff;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
}

.home_who_are_you_title {
  width: 80%;
  margin: auto;
}
.home_who_are_you_title h4 {
  text-align: left;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1000px) {
  .blog_items {
    width: 100%;
  }
  .input_and_btn_holder {
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .home_page_how_to_apply_container {
    display: block;
    width: 100%;
  }
  .home_page_staper_container {
    margin: auto;
    margin-bottom: 40px;
  }
  .home_apply_step_video_container {
    width: 100%;
    margin-left: 0px;
  }
  .apply_step_video_style {
    border-radius: 0px;
  }
  .subscription_container {
    width: 90%;
    height: auto;
    margin-bottom: -120px;
  }
  .subscriotion_input_style {
    display: block;
    width: 100%;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .input_and_btn_holder {
    display: block !important;
    text-align: center;
  }
  .subscription_btn {
    width: 200px;
    margin: auto;
    margin-top: 10px;
  }
  .subscriotion_heading_text {
    font-size: 25px;
  }
}

@media only screen and (max-width: 450px) {
  .home_page_staper_container {
    width: 90%;
  }
}
