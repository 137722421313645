.mail_management_container {
  width: 90%;
  margin: auto;
}
.mail_user_list p {
  font-size: 16px !important;
  color: white;
}
.mail_user_list {
  width: 95%;
  margin: auto;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #3db166;
  margin-bottom: 15px;
}
.select_all_check_box {
  text-align: left;
  margin-left: 5%;
  margin-bottom: 20px;
}
.mail_user_list_index {
  margin: 0px;
  margin-left: 10px;
  width: fit-content;
}
.mail_search_input {
  border: 1px gray solid;
  padding: 5px 10px;
  border-radius: 10px;
  color: gray;
  min-width: 150px;
  margin-left: 20px;
  width: 70%;
}
.mail_search_input:focus {
  outline: none;
}
