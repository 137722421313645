.main_nav_bar_holder {
  width: 100%;
  /* background-image: linear-gradient(
      to right top,
      #192f596e,
      #192f596e,
      #192f596e,
      #192f596e,
      #192f596e
    ),
    url("/public/images/navbar/main_navbar_back_img.png"); */
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
}

.children_container_holder {
  margin-top: 70px;
  margin-bottom: 50px;
}

.main_navbar {
  width: 98%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_nav_link_holder {
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.link_text_style {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
}

.link_text_style:hover {
  color: #3db166;
}

.action_btn_holder {
  width: 15%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navbar_money_icon_style {
  height: 30px;
  width: 30px;
}

.basic_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

@media only screen and (max-width: 1100px) {
  .action_btn_holder {
    display: none;
  }
  .main_nav_link_holder {
    display: none;
  }
}
