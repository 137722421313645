.pagination-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  border-top: 0px;
  padding: 15px;
}
.pagination-container p {
  margin: 0px;
}
.page-number-box {
  width: 400px;
  display: flex;
  align-items: center;
}
.pagenumber-input {
  width: 70px;
}
.page-number-input-label {
  padding-right: 10px;
}
.page-size-box {
  width: 225px;
  display: flex;
  align-items: center;
}
.page-size-dropdown {
  width: 30% !important;
  margin-left: 10px;
}
.current-page-number-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 170px;
}
@media only screen and (max-width: 600px) {
  .pagination-container {
    display: block;
  }
  .current-page-number-box {
    margin: auto;
    margin-bottom: 10px;
  }
  .page-number-box {
    margin: auto;
    margin-bottom: 10px;
    width: fit-content;
  }
  .page-size-box {
    margin: auto;
    margin-bottom: 10px;
    width: fit-content;
  }
}
