.table_icon_style {
  font-size: 25px;
  cursor: pointer;
}

.closed_icon {
  font-size: 17px;
  color: gray;
  font-weight: 600;
}

.application_table_container {
  width: 98%;
  margin: auto;
  margin-top: 30px;
  border-radius: 10px;
}

.green_table_head {
  /* background-color: #3db166; */
  background-color: #192f59;
  font-weight: 700;
  color: white;
  border-radius: 10px;
}

td {
  padding: 20px !important;
}

th {
  padding: 20px !important;
}

.table_header_row:first-child {
  border-radius: 10px;
}

.search_field_holder {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.search_field_style {
  padding: 5px 5px;
  border: 1px gray solid;
}

.search_field_style:focus {
  outline: none;
}

.table_sub_container {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 20px;
}
.table_sub_container::-webkit-scrollbar {
  display: none;
}
.table_header_row th:first-child {
  border-radius: 10px 0 0 0;
}
.table_header_row th:last-child {
  border-radius: 0 10px 0 0;
}
