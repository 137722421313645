.home_page_why_apply_container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: start;
  width: 90%;
  margin: auto;
}

.home_page_why_apply_card {
  padding: 20px;
  background-color: #efefef;
  width: 250px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
}
.home_page_why_apply_card_img {
  width: 60px;
  height: 60px;
}
.home_page_why_apply_card_title {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin: 10px 0px;
}
.home_page_why_apply_card_desc {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
}
.home_info_title_video_container {
  width: 85%;
  margin: auto;
}
.home_info_title_video_label {
  width: 100%;
  height: 400px;
  margin-top: 10px;
  border: 3px dashed rgb(218, 218, 218);
  padding: 3px;
  display: block;
}

.webcover_holder {
  width: 80%;
  margin: auto;
}

.web_cover {
  width: 100%;
  height: 400px;
  margin-top: 10px;
  border: 3px dashed rgb(218, 218, 218);
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.title_video_style {
  width: 100%;
  height: 387px;
  object-fit: cover;
}
.home_info_title_video_btn_container {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0px;
}
.home_info_title_video_btn {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
}

.home_info_how_to_apply_container {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-evenly;
}
.home_info_staper_container {
  background-color: #192f59;
  color: white;
  width: 55%;
  padding: 20px;
  border-radius: 20px;
}
.home_info_staper_container p {
  text-align: start;
}
.home_info_video_container {
  width: 40%;
}
.country_cover_img_style {
  width: 100%;
  height: 277px;
  object-fit: cover;
}
.country_cover_image {
  width: 100%;
  height: 290px;
  margin-top: 10px;
  border: 3px dashed rgb(218, 218, 218);
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.country_upload_placeholder_img_style {
  height: 120px;
  width: 120px;
}
.home_info_country_list_container {
  width: 75%;
  margin: auto;
}
.my-masonry-grid {
  display: flex;
  margin-left: -8px;
  width: auto;
  height: 400px;
  overflow-y: scroll;
  justify-content: center;
  margin: 30px auto;
  cursor: pointer;
}
.my-masonry-grid::-webkit-scrollbar {
  display: none;
}

.my-masonry-grid_column {
  padding-left: 8px;
  background-clip: padding-box;
  max-width: 300px;
  max-height: 400px;
}

.my-masonry-grid_item {
  margin-bottom: 8px;
  max-width: 300px;
  max-height: 400px;
  position: relative;
}
.masonry-grid-image {
  max-width: 300px;
  max-height: 350px;
  object-fit: cover;
}
.masory_country_name {
  position: absolute;
  bottom: 0px;
  left: 50%;
  z-index: 10;
  color: white;
  transform: translate(-50%, 0%);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0px;
  padding: 10px 0px;
  background: linear-gradient(
    180deg,
    rgba(33, 59, 108, 0) -6.05%,
    #111111c7 41.32%
  );
  width: 100%;
}

@media only screen and (max-width: 750px) {
  .home_info_how_to_apply_container {
    display: block;
  }
  .home_info_staper_container {
    width: 100%;
  }
  .home_info_video_container {
    width: 100%;
  }
}
