.blog_box_content_holder {
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
  width: 90%;
  margin: auto;
  margin-top: 50px;
}

.blog_image_class {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px 20px 0px 0px;
}

.blog_information {
  width: 100%;
  padding: 25px;
}

.blog_box_author_text_style {
  font-size: 16px !important;
  font-weight: 600;
  color: black !important;
  text-transform: capitalize;
}

.blog_abstract_view {
  text-align: left;
  max-height: 150px;
  overflow-y: hidden;
  color: #646464;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.gradient {
  position: absolute;
  width: 100%;
  height: 150px;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 17%,
    rgba(88, 87, 110, 0) 80%
  );
}

.blog_box_heading_holder {
  width: 100%;
}

.blog_box_title_text_style {
  color: #192f59;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}

.box_blog_button_holder {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
