.user_navbar {
  background-color: #192f59;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}
.user_nav_link_container {
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.user_link_text_style {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}

.user_link_text_style:hover {
  color: #3db166;
}

.user_navbar_logo_style {
  height: 54px;
  width: 230px;
  /* background-color: white; */
  margin-top: 10px;
}

.user_profile_logout_btn_container {
  width: 250px;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.user_profile_avatar_style {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.username_text_style {
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  margin: 0px;
}

.logout_icon_style {
  font-size: 25px;
  color: #3db166;
  cursor: pointer;
}

.children_component_holder {
  margin-top: 50px;
  margin-bottom: 50px;
}

.user_mobile_nav_manu {
  display: none;
}
.user_mobile_nav_manu_icon {
  color: white;
  font-size: x-large;
  margin-right: 10px;
}
.user_navbar_drawer {
  width: 250px !important;
  /* text-align: center; */
}
.user_mobile_link_text_style {
  color: #192f59;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  /* padding: 10px 20px; */
  line-height: 24px;
}
.user_mobile_link_text_style_container {
  margin: 15px 0px;
}

.select_navbar_style {
  border: none;
  background-color: #192f59;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  width: 65px;
}

.nav_option_style {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 1100px) {
  .user_mobile_nav_manu {
    display: block;
  }
  .user_nav_link_container {
    display: none;
  }
  .user_profile_logout_btn_container {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .user_navbar_logo_style {
    height: 50px;
    width: 160px;
  }
}
