.about_us_container_style {
  width: 90%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.email_container_style {
  width: 50%;
  padding: 15px;
}

.solical_media_style {
  width: 50%;
  padding: 15px;
}

/* Email Box Styles */

.email_data_holder {
  width: 100%;
  margin: auto;
  padding: 10px;
  text-align: left;
}

.email_reacg_out_heading {
  font-size: 18px;
  color: #fcb64d;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 500;
}

.email_heading_style {
  font-size: 18px;
  color: #302d3a;
  font-weight: 700;
  margin-bottom: 20px;
}

.email_desc_style {
  font-size: 18px;
  color: #a2a2a2;
  font-weight: 500;
  margin-bottom: 20px;
}

.icon_email_holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contact_us_icons_style {
  border-radius: 200px;
  font-size: 50px;
  padding: 5px 10px;
  background-color: #192f59;
  color: white;
}

.contact_us_icons_style_solical {
  border-radius: 200px;
  font-size: 50px;
  padding: 5px 10px;
  background-color: #192f59;
  color: white;
}

.email_text_style {
  font-size: 16px;
  font-weight: 700;
  color: #302d3a;
  margin-bottom: 0;
  margin-left: 20px;
  text-align: left;
  word-break: break-all;
}

.email_format_holder {
  margin-top: 30px;
}

.email_format_style {
  font-size: 15px;
  color: #777676;
  margin-bottom: 3px;
}

/* END */

.social_icon_holder {
  display: flex;
  justify-content: flex-start;
}

@media only screen and (max-width: 980px) {
  .about_us_container_style {
    display: block;
    width: 95%;
    margin: auto;
  }

  .email_container_style {
    width: 100%;
  }

  .solical_media_style {
    width: 100%;
  }
}
