.content_update_tab_holder {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  border-bottom: 1px solid rgb(187, 187, 187);
}

.content_update_button_style {
  border: none;
  padding: 10px;
  background-color: white;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 500;
}

.selected_content_update_btn_style {
  color: #008000;
  border-bottom: 3px solid #008000;
  font-weight: 500;
}

@media only screen and (max-width: 800px) {
  .content_update_tab_holder {
    width: 98%;
    margin: auto;
    margin-top: 30px;
  }
}
