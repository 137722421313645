.student_sign_up_container {
  width: 50%;
  margin: auto;
  margin-top: 20px;
}

@media only screen and (max-width: 800px) {
  .student_sign_up_container {
    width: 95%;
  }
}
