.suggestion_holder {
  padding: 5px;
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.modal_heading_style {
  font-size: 25px;
  color: black;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}

.matching_text_style {
  font-size: 16px;
  color: gray;
  font-weight: bold;
  text-align: center;
}

.img_desc_holder {
  height: 200px;
  width: 100%;
  margin: auto;
}

.img_desc_holder {
  position: relative;
}

.modal_image_style {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.course_detail_holder {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.course_name_style {
  color: #f2f2f2;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-left: 10px;
}

.course_decepline_style {
  color: #f2f2f2;
  font-size: 16px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.Suggestion_content {
  margin-top: 20px;
  width: 100%;
}

.graduation_impr_text {
  color: black;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.badge_text_style {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 3px;
}

.examination_name_style {
  color: black;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}

.required_score {
  font-size: 18px;
  color: black;
  font-weight: 600;
}
