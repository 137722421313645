/* Statistic Block CSS */
.statistic_block_holder {
  margin-top: 40px;
}

.statistic_block {
  min-height: 280px !important;
  width: 100% !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-top: 5px solid #192f59;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 15px;
  margin-bottom: 40px;
  cursor: pointer;
  height: 90%;
}

.heading_holder {
  margin-top: 30px;
  text-align: center;
}

.heading_text_style {
  font-size: 19px;
  font-weight: 700;
  color: #192f59;
}

.sub_heading_holder {
  width: 98%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
}

.sub_heading_text_style {
  font-size: 16px;
  color: #192f59;
  font-weight: 600;
}

.counting_holder {
  width: 120px;
  height: 45px;
  margin: auto;
  background-color: #192f59;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-top: 40px;
}

.counting_text_style {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.application_title_holder {
  margin-top: 50px !important;
}

@media only screen and (max-width: 500px) {
  .statistic_block {
    margin: auto;
  }

  .date_holder {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .statistic_block {
    margin: auto;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 300px) {
  .statistic_block {
    height: 300px !important;
    width: 100% !important;
    margin-bottom: 40px;
  }

  .sub_heading_text_style {
    text-align: center;
  }
}
