.login_form_holder {
  width: 50%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.login_form_style {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  padding: 15px;
}

.login_field_holder {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}

.login_input_field_style {
  width: 90%;
  margin: auto;
  padding: 18px 10px 18px 18px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  font-size: 17px;
}

::placeholder {
  color: black;
  font-size: 17px;
  opacity: 1; /* Firefox */
}

.forget_password_holder {
  width: 100%;
  margin: auto;
  text-align: left;
}

.forget_password_text {
  text-align: end;
  font-weight: 600;
  color: rgb(192, 192, 192);
  margin-top: 5px;
}
.signup_link {
  color: #192f59;
  font-weight: 600;
  text-decoration: none;
}

.login_btn_style {
  background-color: #4caf50;
  width: 95%;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

/* MEDIA QUERY */
@media only screen and (max-width: 700px) {
  .login_form_holder {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .login_form_holder {
    width: 100%;
  }
}
