.selectArrow {
  width: 18px;
  height: 40px;
  color: #192f59 !important;
  position: relative;
  top: 10px;
}
.sidebar {
  box-shadow: rgba(0, 0, 0, 0.15) 3.95px 1.95px 5.6px !important;
  border-radius: 15px !important;
  background-color: #f2f2f2 !important;
}
.navIcon img {
  width: 28px;
}
.navComponent {
  border-bottom: #ccb8b882 solid;
  width: 20vw;
  height: 60px;
  display: flex;
  border-width: 2px;
}

.navIcon {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  width: 100%;
}

.navIcon div {
  margin-left: 15px;
  font-size: 12px;
  color: #192f59;
}
.eoFNhi {
  position: static !important;
}
.form-check-input:checked {
  background-color: #3db166 !important;
  border-color: #3db166 !important;
}
.form-check-label {
  font-size: 1.3vw;
  width: 16vw;
}

.form-check-label {
  font-size: 13px !important;
  width: 100% !important;
  padding-left: 10px !important;
}

.form-check-sidebar {
  padding-left: 2ch !important;
}

.ApplyFilter {
  background-color: #3db166 !important;
  color: #ffffff !important;
}

.ApplyFilter:hover {
  background-color: #06ad41 !important;
  color: #ffffff !important;
}

.search_filter_heading_style {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.ApplyFilterPerent {
  width: 81%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 15px;
}
.sidebarNavParent {
  border-radius: 20px;
  background-color: #f2f2f2 !important;
  color: #15171c;
  width: 100%;
  display: flex;
  justify-content: center;
  position: static;
  margin-bottom: 15px;
  top: 0;
  transition: 350ms;
  z-index: 10;
}
.side_bar_icon_style {
  color: black;
}

.SidebarWrap {
  width: 100%;
}

.DropdownLink {
  background: #ffffff;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
}
.DropdownLink:hover {
  background: #ffffff;
  cursor: pointer;
}

/* filter Area  */

.filter_fields_holder {
  width: 98%;
  margin: auto;
  margin-top: 10px;
  padding: 25px 10px;
}

.filter_input_style {
  width: 90%;
  padding: 10px;
  outline: none !important;
  border-radius: 5px;
  border: none;
  border-bottom: 1px solid #c3c3c3;
  background-color: #f2f2f2;
  margin-bottom: 25px;
}

.filter_fields_holder:focus {
  outline: none;
}

/* End */
.SidebarLink {
  display: flex;
  color: #000000;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
}
.SidebarLink:hover {
  background: #ffffff;
  cursor: pointer;
}

@media only screen and (max-width: 770px) {
  .sidebar {
    width: 100%;
  }
  .navComponent {
    width: 100%;
  }
  .navIcon div {
    font-size: 19px;
  }
}
