.aboutus_content_holder {
  margin: 30px 0px 30px;
}

/* ARE YOU CURIOUS BLOCK */

.curious_main_block {
  width: 80%;
  margin: auto;
}

.curious_heading {
  font: 22px;
  text-transform: uppercase;
  text-align: left;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  letter-spacing: 3px;
  font-weight: 600;
}

.curious_detail_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.curious_text_area {
  width: 40%;
}

.courious_main_text_style {
  font-size: 50px;
  text-align: left;
  font-weight: 700;
  color: #008000;
  line-height: 1.2;
}

.curious_sub_text_style {
  font-size: 20px;
  text-align: left;
  color: gray;
}

.curious_image_area {
  width: 50%;
  height: 300px;
  object-fit: cover;
}

.curious_image_style {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* END */

/* GOAL STATEMENT BLOCK */

.goal_state_main {
  width: 80%;
  margin: auto;
  margin-top: 140px;
}

.values_goals_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.goal_values_area {
  width: 60%;
  margin: auto;
  background-color: #192f59;
  padding: 15px;
  border-radius: 10px;
}

.goal_statement_area {
  width: 40%;
  margin: auto;
  padding-left: 30px;
}

.value_container {
  color: white;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.value_icon_style {
  font-size: 50px;
}

.value_name_style {
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 20px;
  color: white;
}

.value_description_style {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.774);
}

.word_from_owner_style {
  font-size: 18px;
  text-align: left;
  line-height: 1.7;
  color: rgb(85, 84, 84);
}

/* END */

/* WEO WE ARE BLOCK */

.who_we_are_main {
  width: 80%;
  margin: auto;
  margin-top: 100px;
}

.who_we_container {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(128, 128, 128, 0.349);
  border-radius: 5px;
  padding-top: 10px;
  height: 100%;
}

.who_we_icon_holder {
  width: 22%;
  text-align: center;
  color: #192f59;
}

.what_we_are_icon_style {
  font-size: 40px;
}

.who_we_content_holder {
  text-align: left;
  width: 78%;
}

.who_we_are_headings {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 3px;
}

.who_we_are_description {
  font-size: 16px;
  color: gray;
}

/* END */

/* STATICTICS BLOCK */

.statistics_holder {
  width: 80%;
  margin: auto;
  margin-top: 100px;
}

.statistics_icon_style {
  font-size: 45px;
}

.stat_number_style {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 0px;
}

.stat_label_style {
  font-size: 22px;
  font-weight: 600;
}

.stat_container {
  border-bottom: 3px solid rgba(128, 128, 128, 0.411);
  border-right: 3px solid rgba(128, 128, 128, 0.411);
  border-radius: 10px;
}

/* END */

/* OUR STORY BLOCK */

.our_story_main {
  width: 80%;
  margin: auto;
  margin-top: 120px;
}

.story_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.story_text_holder {
  width: 50%;
}

.story_text_style {
  text-align: left;
  font-size: 16px;
  color: gray;
}

.story_image_holder {
  width: 40%;
  margin: auto;
}

.image_style {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* END */

/* OUR CULTURE BLOCK */

.our_culture_main {
  width: 80%;
  margin: auto;
  margin-top: 140px;
}

.our_culture_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.our_culture_image_holder {
  width: 50%;
  padding: 15px;
}

.our_culture_text_holder {
  width: 50%;
}

.our_culture_text {
  text-align: left;
  font-size: 16px;
  color: gray;
}

.our_culture_image_style {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* END */

/* OUR TEAM BLOCK */

.our_team_main {
  width: 80%;
  margin: auto;
  margin-top: 120px;
}

.member_image_style {
  height: 85%;
  width: 85%;
  border-radius: 50%;
}

.member_detail_holder {
  margin-top: 10px;
}

.mamber_name_style {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 2px;
}

.member_designation_style {
  font-size: 18px;
  color: gray;
  font-weight: 600;
}

/* END */

/* JOIN US AREA */

.join_us_main {
  background-color: #0000000a;
  padding: 50px;
  margin-top: 50px;
}

.join_us_content {
  width: 45%;
  margin: auto;
}

.join_us_heading {
  font-size: 35px;
  font-weight: 700;
}

.join_us_desc_1 {
  font-size: 20px;
  margin-bottom: 0px;
}

.join_us_btn {
  padding: 8px 30px;
  border: none;
  background-color: #008000;
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  border-radius: 50px;
}

.join_us_btn:hover {
  background-color: #008000de;
}

/* END */

@media only screen and (max-width: 1300px) {
  .our_culture_content {
    align-items: normal;
  }
}

@media only screen and (max-width: 1100px) {
  .curious_main_block {
    width: 95%;
    margin: auto;
  }

  .goal_state_main {
    width: 95%;
    margin: auto;
    margin-top: 50px;
  }

  .who_we_are_main {
    width: 95%;
    margin: auto;
    margin-top: 30px;
  }

  .our_story_main {
    width: 95%;
    margin: auto;
    margin-top: 50px;
  }

  .our_culture_main {
    width: 95%;
    margin: auto;
    margin-top: 50px;
  }

  .our_team_main {
    width: 95%;
    margin: auto;
    margin-top: 50px;
  }

  .join_us_content {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .curious_text_area {
    width: 60%;
    margin: auto;
  }
  .curious_image_area {
    width: 40%;
    margin: auto;
  }

  .statistics_holder {
    width: 95%;
    margin: auto;
    margin-top: 50px;
  }

  .stat_container {
    margin-bottom: 40px;
  }

  .statistics_icon_style {
    font-size: 40px;
    margin-bottom: -5px;
  }

  .stat_number_style {
    font-size: 40px;
    margin-bottom: -10px;
  }

  .stat_label_style {
    font-size: 20px;
  }

  .story_text_holder {
    width: 100%;
  }

  .story_image_holder {
    width: 70%;
    margin: auto;
  }
}

@media only screen and (max-width: 800px) {
  .curious_detail_container {
    display: block;
  }

  .curious_text_area {
    width: 95%;
    margin: auto;
  }

  .curious_image_area {
    width: 95%;
    margin: auto;
  }

  .values_goals_area {
    display: block;
  }

  .goal_values_area {
    width: 95%;
  }

  .goal_statement_area {
    width: 95%;
    margin: auto;
    margin-top: 20px;
    padding-left: 0px;
  }

  .story_holder {
    display: block;
  }

  .story_text_style {
    font-size: 16px;
    color: gray;
  }

  .our_culture_content {
    display: block;
  }

  .our_culture_image_holder {
    width: 100%;
    height: 350px;
  }

  .our_culture_text_holder {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .courious_main_text_style {
    font-size: 40px;
    text-align: center;
  }

  .word_from_owner_style {
    font-size: 16px !important;
    font-weight: 400;
  }

  .curious_sub_text_style {
    font-size: 16px;
    text-align: center;
  }

  .who_we_container {
    display: block;
  }

  .who_we_icon_holder {
    width: 100%;
    margin: auto;
  }

  .who_we_content_holder {
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .story_image_holder {
    width: 100%;
  }

  .mamber_name_style {
    font-size: 18px;
  }

  .member_designation_style {
    font-size: 16px;
  }

  .join_us_content {
    width: 100%;
  }
}
