.add_student_form {
  width: 60%;
  margin: auto;
}

.student_input_container {
  width: 100%;
  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.student_label_field_holder_flex_style {
  display: block;
  text-align: left;
  margin-bottom: 20px;
}

.student_label_style {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.student_input_field_style {
  display: block;
  width: 90%;
  padding: 8px 10px 8px 10px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  font-size: 17px;
}

.profile_photo_info_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student_profile_img_container {
  width: 20%;
}

.general_details_holder {
  width: 75%;
}

.actual_file_uopload_input_field {
  display: none;
}

.student_profile_img_label_style {
  height: 150px;
  width: 90%;
  margin: auto;
  border: 1px solid black;
}

.upload_place_holder_style {
  height: 150px;
  width: 90%;
  margin: auto;
}

.add_agent_btn_holder {
  display: flex;
  justify-content: flex-end;
}

.user_uploaded_image_style {
  height: 150px;
  width: 100%;
  margin: auto;
  object-fit: cover;
}

.cv_file_holder_div_style {
  display: block;
  width: 100%;
  padding: 8px 10px 8px 10px;
  border: 2px solid #192f59;
  border-radius: 10px;
  font-size: 15px;
  color: black;
  font-weight: 500;
  font-size: 17px;
  height: 45px;
}

.student_btn_holder {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add_agent_btn_style {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

@media only screen and (max-width: 800px) {
  .add_student_form {
    width: 90%;
  }
  .profile_photo_info_holder {
    display: block;
  }
  .student_profile_img_container {
    width: 90%;
    margin: 0px auto 20px auto;
  }
  .general_details_holder {
    width: 100%;
  }
}
