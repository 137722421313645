.course_page_main_container {
  display: flex;
  justify-content: space-evenly;
}
.course_page_sidbe_container {
  width: 22%;
  /* border: 1px solid black; */
}
.university_course_container {
  width: 75%;
  /* border: 1px solid black; */
}
.course_page_mobile_sidebar_container {
  display: none;
}
.course_page_show_filter_btn {
  background-color: #3db166; /* Green */
  border: none;
  color: white;
  padding: 8px 25px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 750px) {
  .course_page_main_container {
    display: block;
    margin: auto;
  }

  .course_page_sidbe_container {
    display: none;
  }
  .course_page_mobile_sidebar_container {
    display: block;
    width: 90%;
    margin: auto;
  }
  .university_course_container {
    margin: auto;
  }
}

@media only screen and (max-width: 650px) {
  .university_course_container {
    width: 90%;
  }
}

@media only screen and (max-width: 450px) {
  .university_course_container {
    width: 98%;
  }
}
