.subscriber_list_holder {
  width: 60%;
  margin: auto;
}

.subscriber_box {
  width: 95%;
  margin: auto;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #3db166;
  margin-bottom: 15px;
}

.subscriber_box:hover {
  background-color: #3db166e8;
}

.subscriber_box p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
  color: white;
}
.index_text {
  width: 10%;
}
.email_text_style {
  width: 60%;
  text-align: left;
}
.date_text_style {
  width: 20%;
}

.sub_icon_style {
  font-size: 25px;
  color: white;
  cursor: pointer;
}

.subscriber_pagination_holder {
  margin-top: 30px;
}

.new_email_btn_holder {
  width: 97%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 1100px) {
  .subscriber_list_holder {
    width: 95%;
  }
  .subscriber_box {
    width: 80%;
    display: block;
  }

  .sub_icon_style {
    margin-top: 10px;
  }
  .index_text {
    width: 100%;
  }
  .email_text_style {
    width: 100%;
    text-align: center;
  }

  .date_text_style {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .subscriber_box {
    width: 95%;
    display: block;
  }

  .subscriber_box p {
    font-size: 15px;
  }
}
