.compose_email_section {
  width: 60%;
  margin: auto;
}

@media only screen and (max-width: 800px) {
  .compose_email_section {
    width: 95%;
    margin: auto;
  }
}
